import { FeaturesService } from './features/features.service';
import { AppParamsService } from './app.params.service';
import { Member } from '@components/members';
import { MembersOptions } from '@interfaces/members-options.interface';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import {
  Observable,
  BehaviorSubject,
  of,
  forkJoin,
  throwError,
  combineLatest,
} from 'rxjs';
import {
  switchMap,
  catchError,
  map,
  first,
  filter,
  take as rxjsTake,
} from 'rxjs/operators';
import { MemberBenefits } from '@interfaces/member-benefits.model';
import { MemberDependent } from '@interfaces/member-dependent.model';
import { SettingsService } from '@services/settings.service';
import { ObservableError } from '@interfaces/observable-error.model';
import { DefaultMember } from '@interfaces/default-member.model';
import { DefaultMemberConfig } from '@interfaces/default-member-config.model';
import { LocationService } from './location/location.service';
import { MedicationFinderService } from '@services/medication-finder.service';
import { find, get, orderBy, take } from 'lodash';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root',
})
export class MembersService {
  public member: BehaviorSubject<Member> = new BehaviorSubject(undefined);
  public incentivesEnabled: BehaviorSubject<boolean> = new BehaviorSubject(
    undefined
  );
  public defaultMember: Observable<DefaultMember> = this.getDefaultMember();
  public noMemberBenefits: Observable<boolean>;
  private noMemberBenefitsSubject: BehaviorSubject<boolean> =
    new BehaviorSubject(false);

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private appParamsService: AppParamsService,
    private featuresService: FeaturesService,
    private settingsService: SettingsService,
    private locationService: LocationService,
    private medicationFinderService: MedicationFinderService,
    private configurationService: ConfigurationService
  ) {
    this.noMemberBenefits = this.noMemberBenefitsSubject.asObservable();
  }

  // Requests member once auth resolves and feature flags permit.
  // undefined = Unresolved.
  // error = "Unauthenticated" or "Cost Disabled"
  public getMember(options: MembersOptions = {}): Observable<Member> {
    return this.authService.authStatus.pipe(
      // When auth is resolved
      first((auth) => auth.resolved),
      // Determine if member should be requested based on auth status
      switchMap((auth) => this.shouldRequestMember(auth, true)),
      catchError(() => of(false)),
      // Make member request
      switchMap((enabled: boolean) => {
        if (enabled) {
          return this.requestMember(options);
        }
        return of(this.handleMemberResponse(null));
      })
    );
  }

  // Get member benefits by member number.
  // If no member number given, the primary member benefits will be requested.
  public getBenefits(
    memberNumber: string = null,
    options: MembersOptions = {}
  ): Observable<MemberBenefits[]> {
    return this.authService.authStatus.pipe(
      // When auth is resolved
      first((auth) => auth.resolved),
      // Determine if member should be requested based on auth status and cost
      switchMap((auth) => this.shouldRequestMember(auth, false)),
      // Make member request
      switchMap((enabled: boolean) => {
        if (enabled) {
          return this.requestBenefits(memberNumber, options).pipe(
            first((benefits) => !!benefits)
          );
        }
        return of([]);
      })
    );
  }

  // Get dependent by member number.
  // If no member number given, primary dependent is returned.
  public getDependentByNumber(memberNumber: string): MemberDependent {
    const member = this.member.getValue();
    if (!member) {
      return null;
    }
    if (!memberNumber) {
      return member.dependents[0];
    }
    return find(member.dependents, { id: memberNumber });
  }

  public setIncentivesEnabledFromConfig(member: Member): Observable<any> {
    return this.settingsService.getSetting('incentives_enabled').pipe(
      catchError(() => of({})),
      map((setting) => {
        if (setting === false) {
          member.incentives_enabled = setting;
          this.setIncentiveEnabled(setting);
        }
      })
    );
  }

  public updateNoMembersBenefits(benefits: any[]): boolean {
    this.noMemberBenefitsSubject.next(!benefits?.length);
    return this.noMemberBenefitsSubject.getValue();
  }

  private getDefaultMember(): Observable<DefaultMember> {
    return combineLatest([
      this.authService.authStatus,
      this.settingsService.getSetting('default_member', DefaultMemberConfig),
      this.locationService.geo.pipe(filter((geo) => !!geo)),
    ]).pipe(
      switchMap(([authStatus, defaultMemberConfig, location]) => {
        return this.defaultMemberStatus(
          authStatus.auth_status,
          defaultMemberConfig,
          location.state_code
        );
      })
    );
  }

  private defaultMemberStatus(
    auth: boolean,
    member: DefaultMemberConfig,
    stateCode: string
  ): Observable<DefaultMember> {
    const { user, allowed_states } = member;

    if (auth) {
      return of(null);
    }
    if (user) {
      const augmentedUser = user.setInAllowedLocation(
        allowed_states.includes(stateCode)
      );
      return of(augmentedUser);
    }
    return of(null);
  }

  private requestMember(options: MembersOptions): Observable<Member> {
    const url = `/api/members/member_details.json`;
    const params = this.getRequestParams(options);
    return this.configurationService.listenForResolvedSignature().pipe(
      rxjsTake(1),
      switchMap((_sig) => {
        return this.http
          .get(url, { withCredentials: true, params: params })
          .pipe(
            catchError(() => of(null)),
            switchMap((response: any) => this.augmentMember(response, options)),
            map((member: Member) => this.handleMemberResponse(member))
          );
      })
    );
  }

  private requestBenefits(
    memberNumber: string,
    options: MembersOptions
  ): Observable<MemberBenefits[]> {
    const url = `/api/members/member_benefits.json`;
    options.memberNumber = memberNumber;
    const params = this.getRequestParams(options);
    return this.configurationService.listenForResolvedSignature().pipe(
      rxjsTake(1),
      switchMap((_sig) => {
        return this.http
          .get(url, { withCredentials: true, params: params })
          .pipe(
            catchError(() => of(null)),
            map((result) => result || { member_benefits: [] }),
            map((response: any) => this.handleBenefitsResponse(response))
          );
      })
    );
  }

  private augmentMember(
    response: any,
    options: MembersOptions
  ): Observable<Member> {
    if (!response) {
      return of(null);
    }
    const member = new Member(response);
    const augments: Observable<Member>[] = [];
    augments.push(this.augmentMemberWithDependents(member, options));
    augments.push(this.augmentMemberWithBenefits(member, options));
    if (member.incentives_enabled) {
      augments.push(this.augmentMemberWithShoppingHistory(member, options));
      augments.push(
        this.augmentMemberWithPreferredContactMethods(member, options)
      );
    }
    augments.push(this.augmentMemberWithRxEligibility(member));
    return forkJoin(augments).pipe(
      switchMap(() => this.setIncentivesEnabledFromConfig(member)),
      switchMap(() => of(member))
    );
  }

  private augmentMemberWithRxEligibility(member: Member): Observable<Member> {
    return this.medicationFinderService
      .getEligibility()
      .pipe(map((eligible) => member.setRxEligible(eligible)));
  }

  private augmentMemberWithDependents(
    member: Member,
    options: MembersOptions
  ): Observable<Member> {
    const url = `/api/members/member_list.json`;
    const params = this.getRequestParams(options);
    return this.configurationService.listenForResolvedSignature().pipe(
      rxjsTake(1),
      switchMap((_sig) => {
        return this.http
          .get(url, { withCredentials: true, params: params })
          .pipe(
            catchError(() => of(null)),
            map((result) => result || { member_list: [] }),
            map((dependents: any) =>
              member.setDependents(dependents.member_list)
            )
          );
      })
    );
  }

  private augmentMemberWithBenefits(
    member: Member,
    options: MembersOptions
  ): Observable<Member> {
    return this.requestBenefits(null, options).pipe(
      catchError(() => of(null)),
      map((result) => result || { member_benefits: [] }),
      map((benefits: MemberBenefits[]) => member.setBenefits(benefits))
    );
  }

  private augmentMemberWithShoppingHistory(
    member: Member,
    options: MembersOptions
  ): Observable<Member> {
    const url = `/api/members/shopping_history.json`;
    const params = this.getRequestParams(options);
    return this.http.get(url, { withCredentials: true, params: params }).pipe(
      catchError(() => of(null)),
      map((result) => result || { shopping_history: [] }),
      map((history: any) =>
        member.setIncentiveHistory(history.shopping_history)
      )
    );
  }

  private augmentMemberWithPreferredContactMethods(
    member: Member,
    options: MembersOptions
  ): Observable<Member> {
    const url = `/api/members/preferred_contact_methods.json`;
    const params = this.getRequestParams(options);
    return this.http.get(url, { withCredentials: true, params: params }).pipe(
      catchError(() => of(null)),
      map((result) => result || {}),
      map((methods: any) => member.setPreferredContactMethods(methods))
    );
  }

  private getRequestParams(options: MembersOptions): any {
    const shouldCache = get(options, 'shouldCache', true);
    const params: any = {};
    if (!shouldCache) {
      params.cache = 'false';
    }
    const memberNumber = get(options, 'memberNumber', null);
    if (memberNumber) {
      params.member_number = memberNumber;
    }
    return params;
  }

  private shouldRequestMember(
    auth: any,
    requestMember: boolean
  ): Observable<boolean> {
    if (auth.auth_status) {
      return this.featuresService.getFeatureFlags().pipe(
        first((features) => !!features),
        switchMap((features) => {
          if (requestMember || features?.cost) {
            return of(true);
          }
          return this.handleMemberError('Cost Disabled');
        })
      );
    }
    return this.handleMemberError('Unauthenticated');
  }

  private handleMemberResponse(member: Member): Member {
    if (member) {
      this.appParamsService.setMemberParams(member.primary);
      this.member.next(member);
      this.setIncentiveEnabled(!!member.incentives_enabled);
    } else {
      this.member.next(null);
      this.setIncentiveEnabled(null);
    }
    return member;
  }

  private handleMemberError(error: string): Observable<never> {
    this.setIncentiveEnabled(null);
    return throwError(() => new ObservableError({ message: error }));
  }

  private setIncentiveEnabled(enabled: boolean) {
    this.incentivesEnabled.next(enabled);
  }

  private handleBenefitsResponse(response: any): MemberBenefits[] {
    response = response.member_benefits || [];
    let newBenefits = response.map((item) => new MemberBenefits(item));
    if (newBenefits.length > 1) {
      newBenefits = orderBy(newBenefits, ['tier_number'], ['asc']);
      newBenefits = newBenefits.filter((item) => item.tier_number !== 0);
      newBenefits = take(newBenefits, 2);
    }
    return newBenefits;
  }
}
