import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { GatedEntryStatus } from '@interfaces/gated-entry-status.interface';
import { GatedEntryOverlayConfig } from '@interfaces/gated-entry-overlay-config.model';
import { distinctUntilChanged, first, switchMap } from 'rxjs/operators';
import { SettingsService } from '@services/settings.service';
import { LocationService } from '@services/location/location.service';
import { AppParamsService } from '@services/app.params.service';
import { AuthStatus } from '@interfaces/auth-status.model';
import { StorageUtilities } from '@utilities/storage.utilities';
import { GatedEntryOverlayComponent } from '@components/gated-entry-overlay/gated-entry-overlay.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class GatedEntryService {
  public status: BehaviorSubject<GatedEntryStatus>;
  public onDismiss: BehaviorSubject<boolean>;

  private statusClosed: GatedEntryStatus = {
    isOpened: false,
    isClosed: true,
    isEnabled: true,
  };
  private statusOpened: GatedEntryStatus = {
    isOpened: true,
    isClosed: false,
    isEnabled: true,
  };
  private statusDisabled: GatedEntryStatus = {
    isOpened: false,
    isClosed: false,
    isEnabled: false,
  };
  constructor(
    private settingsService: SettingsService,
    private locationService: LocationService,
    private appParamsService: AppParamsService,
    private storage: StorageUtilities,
    private bottomSheet: MatBottomSheet,
    private translateService: TranslateService
  ) {
    this.status = new BehaviorSubject(this.statusClosed);
    this.onDismiss = new BehaviorSubject(null);
  }

  public getGatedEntry(auth: AuthStatus, loadParams: any): Observable<boolean> {
    return combineLatest([
      this.settingsService
        .getSetting('gated_entry', GatedEntryOverlayConfig)
        .pipe(first()),
      this.locationService.isSsoLocation.pipe(distinctUntilChanged()),
      this.status.pipe(distinctUntilChanged()),
    ]).pipe(
      switchMap(([setting, location, status]) => {
        if (
          this.shouldStartGatedEntry(
            auth,
            setting,
            loadParams,
            location,
            status
          )
        ) {
          this.openGatedEntryOverlay(
            auth.auth_status,
            setting,
            this.getInitParamsToStart(loadParams)
          );
          return of(true);
        } else if (!status.isOpened) {
          this.gateDisabled();
          return of(false);
        }
        return of(false);
      })
    );
  }
  public gateOpened(): void {
    this.status.next(this.statusOpened);
  }

  public gateClosed(): void {
    this.status.next(this.statusClosed);
  }

  public gateDisabled(): void {
    this.status.next(this.statusDisabled);
  }

  public dismiss(): void {
    this.onDismiss.next(true);
  }

  private hasUserSelectedCritical(): boolean {
    const criticals = this.appParamsService.getUserSelectedCritical();
    return !!criticals && criticals.length >= 1;
  }

  private shouldStartGatedEntry(
    auth: AuthStatus,
    config: GatedEntryOverlayConfig,
    loadParams: any,
    hasSsoLocation: boolean,
    status: GatedEntryStatus
  ): boolean {
    const gatedEntryEnabled = config.enabled && !auth.auth_status;
    const authUserEnabled = config.authUserEnabled && auth.auth_status;
    const gatedEntryActive = this.storage.sessionStorageGet('gatedEntryActive');
    const gatedEntryCompleted = this.storage.sessionStorageGet('gatedEntryCompleted');
    const hasSwitchedLanguages = loadParams.hasOwnProperty('locale');
    const resolvedLocation = hasSsoLocation || !!loadParams.geo_location;

    if (authUserEnabled && resolvedLocation) {
      return false;
    }

    return (
      (gatedEntryEnabled || authUserEnabled) &&
      !auth.msa_auth_status &&
      !status.isOpened &&
      (config.alwaysShow || !loadParams.geo_location || gatedEntryActive) &&
      (config.alwaysShow || !this.hasUserSelectedCritical() || hasSwitchedLanguages) &&
      !gatedEntryCompleted &&
      !hasSsoLocation
    );
  }

  private getInitParamsToStart(loadParams: any): any {
    let initialAppParams = this.storage.sessionStorageGet('gatedEntryActive');
    initialAppParams = initialAppParams || loadParams || {};
    this.storage.sessionStorageSet('gatedEntryActive', initialAppParams);
    return initialAppParams;
  }

  private openGatedEntryOverlay(
    loggedIn: boolean,
    config: GatedEntryOverlayConfig,
    initialAppParams: any
  ): void {
    const sheetRef = this.bottomSheet.open(GatedEntryOverlayComponent, {
      panelClass: 'full-screen-sheet',
      disableClose: true,
      hasBackdrop: false,
      data: {
        initialAppParams: initialAppParams,
        gatedEntryConfig: config,
        loggedIn: loggedIn,
      },
      ariaModal: false,
      ariaLabel: this.translateService.instant('gated_entry_home_title')
    });
    sheetRef.afterDismissed().subscribe(() => this.endGatedEntry());
  }

  private endGatedEntry(): void {
    this.storage.sessionStorageRemove('gatedEntryActive');
  }
}
