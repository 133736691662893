import {
  Component,
  Output,
  Input,
  EventEmitter,
  ViewChild,
  OnDestroy,
  ChangeDetectorRef,
  OnInit,
  ViewEncapsulation,
  HostListener,
  ViewChildren,
  QueryList,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SubscriptionManager } from '@zelis/platform-ui-components';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
  MatAutocompleteTrigger,
  MatAutocomplete,
} from '@angular/material/autocomplete';
import { MatOptionSelectionChange } from '@angular/material/core';
import { GlobalHeaderAutosuggestCategories } from '@interfaces/global-header-autosuggest-categories.model';
import { CcssService } from '@services/ccss/ccss.service';
import { Breakpoints } from '@classes/breakpoints.class';
import { TermAutosuggest } from '@classes/term-autosuggest.class';
import { SearchTrigger } from '@classes/search-trigger.class';
import { MleService } from '@services/mle.service';
import { GlobalHeaderService } from '../global-header.service';
import { AutocompleteResult } from '@interfaces/autocomplete-result.model';
import {
  filter,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  first,
  tap,
} from 'rxjs/operators';
import { SearchTypeTrigger } from '@interfaces/search-type-trigger.interface';
import { GoogleTagManagerService } from '@services/analytics/google-tag-manager.service';
import { TrackByUtilities } from '@utilities/trackByFn.utilities';
import { getPlaceholderURL } from '@utilities/provider.utilities';
import { TelehealthService } from '@services/telehealth/telehealth.service';
import { AutocompleteResults } from '@interfaces/autocomplete-results.model';
import { MleStatus } from '@interfaces/mle-status.model';
import { MembersService } from '@services/members.service';
import { DefaultMember } from '@interfaces/default-member.model';
import { Store, select } from '@ngrx/store';
import { AuthStoreSelectors } from '@store/auth';
import { AuthStatus } from '@interfaces/auth-status.model';
import { EpisodesOfCareConfigService } from '@services/eoc/eoc.config.service';
import { TelehealthVendor } from '@interfaces/telehealth-vendor.model';
import { TranslateService } from '@ngx-translate/core';
import { SearchRouteType } from '@interfaces/search-route-type.interface';
import { RecentAutosuggestService } from '@services/guided-search/recent-autosuggest.service';
import { SettingsService } from '@services/settings.service';
import { HasHistoricalClaimsConfig } from '@interfaces/has-historical-claims.model';
import { MatInput } from '@angular/material/input';
import { ChipComponent } from '@zelis/dls/chip';
import { setSearchMethodAction } from '@store/search/search.actions';
import { SearchMethods } from '@enums/search-methods.enum';
import { SearchState } from '@store/search/search.state';
import { KeyboardEvents } from '@enums/key-events.enum';

@Component({
  selector: 'app-global-header-autosuggest',
  templateUrl: 'global-header-autosuggest.component.html',
  styleUrls: ['global-header-autosuggest.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GlobalHeaderAutosuggestComponent implements OnDestroy, OnInit, AfterViewInit {
  @Output() clearEvent = new EventEmitter(true);
  @Output() closeMenu = new EventEmitter();

  @Input() selectedGroup: string;
  @Input() preserveTerm: boolean = true;
  @Input() focusInput: boolean = false;
  @Input() offsetScrim: boolean = false;
  @Input() homepage: boolean = false;

  @ViewChild('autosuggestInput', { read: MatAutocompleteTrigger })
  public autosuggestInput: MatAutocompleteTrigger;
  @ViewChild(MatInput)
  public input: MatInput;
  @ViewChild('globalAutocomplete')
  public globalAutocomplete: MatAutocomplete;
  @ViewChildren(ChipComponent, { read: ElementRef })
  public zelisChips: QueryList<ElementRef>;
  @ViewChildren('showMoreLink', { read: ElementRef })
  public showMoreLinks: QueryList<ElementRef>;
  @ViewChildren('listItem', { read: ElementRef })
  public listItems: QueryList<ElementRef>;

  public autocompleteResult: UntypedFormControl = new UntypedFormControl('');
  public categories = {
    providers: new GlobalHeaderAutosuggestCategories({
      limit: 3,
      defaultLimit: 3,
      showMore: false,
      seeMoreMaxLimit: 10,
      seeMoreText: 'global_header_autosuggest_providers_see_more',
      title: 'global_header_autosuggest_providers_title',
      actionText: 'app_global_view_profile',
    }),
    procedures: new GlobalHeaderAutosuggestCategories({
      limit: 3,
      defaultLimit: 3,
      showMore: false,
      seeMoreMaxLimit: 10,
      seeMoreText: 'global_header_autosuggest_procedures_see_more',
      title: 'global_header_autosuggest_procedures_title',
      actionText: 'app_global_search_action',
    }),
    search_specialties: new GlobalHeaderAutosuggestCategories({
      limit: 3,
      defaultLimit: 3,
      showMore: false,
      seeMoreMaxLimit: 10,
      seeMoreText: 'global_header_autosuggest_specialties_see_more',
      title: 'global_header_autosuggest_specialties_title',
      actionText: 'app_global_search_action',
    }),
    telehealth: new GlobalHeaderAutosuggestCategories({
      limit: 3,
      defaultLimit: 3,
      showMore: false,
      seeMoreMaxLimit: 10,
      seeMoreText: 'global_header_autosuggest_telehealth_see_more',
      title: 'global_header_autosuggest_telehealth_title',
      actionText: 'global_header_autosuggest_telehealth_learn_more',
    }),
    rx_coupons: new GlobalHeaderAutosuggestCategories({
      limit: 3,
      defaultLimit: 3,
      showMore: false,
      seeMoreMaxLimit: 10,
      seeMoreText: 'global_header_autosuggest_rx_see_more',
      title: 'global_header_autosuggest_rx_title',
      actionText: 'app_global_search_action',
    }),
    rx_savings: new GlobalHeaderAutosuggestCategories({
      limit: 3,
      defaultLimit: 3,
      showMore: false,
      seeMoreMaxLimit: 10,
      seeMoreText: 'global_header_autosuggest_rx_see_more',
      title: 'global_header_autosuggest_rx_title',
      actionText: 'app_global_search_action',
    }),
    recentSearches: new GlobalHeaderAutosuggestCategories({
      limit: 4,
      defaultLimit: 4,
      showMore: false,
      seeMoreMaxLimit: 10,
      seeMoreText: '',
      title: 'global_autosuggest_recent_searches',
      actionText: 'app_global_search_action',
    }),
    initialPrompt: new GlobalHeaderAutosuggestCategories({
      limit: 1,
      defaultLimit: 1,
      showMore: false,
      seeMoreMaxLimit: 1,
      seeMoreText: '',
      title: null,
      actionText: 'global_autosuggest_initial_search',
    }),
    noResultsPrompt: new GlobalHeaderAutosuggestCategories({
      limit: 1,
      defaultLimit: 1,
      showMore: false,
      seeMoreMaxLimit: 1,
      seeMoreText: '',
      title: null,
      actionText: 'global_header_autosuggest_no_results',
    }),
  };
  public trackByFnIndex: any = TrackByUtilities.trackByFnIndex;
  public trackByFnId: any = TrackByUtilities.trackByFnItemId;
  public trackByFnName: any = TrackByUtilities.getTrackByFn('name');
  public results: AutocompleteResults = new AutocompleteResults();
  public hasHistoricalClaims: boolean;
  public mobileOverlayOpen: boolean = false;
  public isLoading: boolean = false;
  public autosuggestOpen: boolean = false;
  public groupIcons= {
    telehealth: {
      name: 'dls-video-plain-light',
      ariaLabel: 'global_autosuggest_aria_label_telehealth'
    },
    providers: {
      name: null,
      ariaLabel: 'global_autosuggest_aria_label_providers'
    },
    search_specialties: {
      name: 'dls-search-plain-light',
      ariaLabel: 'global_autosuggest_aria_label_search_specialties'
    },
    procedures: {
      name:'dls-dollar-signs-hands-holding-light',
      ariaLabel: 'global_autosuggest_aria_label_procedures'
    },
    recentSearches: {
      name: 'dls-clock-light',
      ariaLabel: 'global_autosuggest_aria_label_recent_searches'
    },
    rx_coupons: {
      name:'dls-medications-capsule-and-tablet-light',
      ariaLabel: 'global_autosuggest_aria_label_rx_coupons'
    },
    rx_savings: {
      name:'dls-medications-capsule-and-tablet-light',
      ariaLabel: 'global_autosuggest_aria_label_rx_savings'
    },
  };
  public showNoResults: boolean = false;
  public groupOrder = [
    'initialPrompt',
    'recentSearches',
    'noResultsPrompt',
    'telehealth',
    'providers',
    'search_specialties',
    'procedures',
    'rx_coupons',
    'rx_savings'
  ];
  public lastGroup: string;
  public selectedChip: string = 'all_results';
  public pendoEvent = new BehaviorSubject<boolean>(false);

  private subscriptions = new SubscriptionManager();
  private resultsSubscription: Subscription = new Subscription();
  private searchTriggerTerm: string;
  private triggerOrigin: string;
  private initialTerm: string;
  private mleEligible: boolean;
  private isPublicCost: boolean;
  private auth: AuthStatus;
  private currentSearchTerm: string;
  private carepathEnabled: boolean = false;
  private telehealthVendors: TelehealthVendor[] = [];
  private focusCheck: NodeJS.Timeout;
  private skipOnEnter: boolean = false;
  private autocompleteList = [];
  private autocompleteIndex = -1;
  private autocompleteFocused = false;
  private useOldAvatars: boolean = false;

  constructor(
    public ccss: CcssService,
    public breakpoints: Breakpoints,
    public recentAutoSuggestService: RecentAutosuggestService,
    private globalHeaderService: GlobalHeaderService,
    private termAutosuggest: TermAutosuggest,
    private searchTrigger: SearchTrigger,
    private ref: ChangeDetectorRef,
    private mleService: MleService,
    private telehealthService: TelehealthService,
    private membersService: MembersService,
    private settingsService: SettingsService,
    private googleTagManagerService: GoogleTagManagerService,
    private carepathConfigService: EpisodesOfCareConfigService,
    private translateService: TranslateService,
    private store: Store<SearchState>
  ) {
    this.getAvatarSetting();
    this.initAutocompleteField();
    this.subscriptions.add(this.subscribeToTermChanges());
    this.resetSearchTrigger();
    this.subscriptions.add(this.subscribeToAutoSuggestTrigger());
  }

  @HostListener('document:keyup.escape', ['$event'])
  onEscapeHandler() {
    this.onTermClear();
  }

  ngOnInit(): void {
    this.subscribeToSearchTrigger();
    this.subscribeToMleStatus();
    this.getAuth();
    this.getDefaultMemberConfig();
    this.subToGlobalHeaderServiceTerm();
    this.getCarepathEnabled();
    this.getTelehealthVendors();
    this.subscribeToHasHistoricalClaimsConfig();
  }

  ngAfterViewInit(): void {
    this.listItems.changes.subscribe((updatedListItems: QueryList<ElementRef>) => {
      this.listItems = updatedListItems;
    });
    if (this.globalAutocomplete) {
      this.handleAutocompleteKeydown();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.destroy();
    this.resultsSubscription.unsubscribe();
  }

  public handleMobileCloseOverlay(e: PointerEvent): void {
    e.preventDefault();
    e.stopPropagation();
    this.updateMobileOverlayStatus();
    this.selectedGroup = '';
    this.unFocusCheck();
  }

  public handleFocus(): void {
    this.skipOnEnter = false;
    if (
      !this.autocompleteResult.value ||
      this.autocompleteResult.value.length < 2
    ) {
      this.openDefaultMenu();
    }
  }

  public onInputChange(value: string): void {
    const whitespaced = !/\S/.test(value);

    if (whitespaced) {
      this.clearEvent.emit();
      this.openDefaultMenu();
      return;
    }
    if (value.length >= 2) {
      this.getAutocompleteResults(this.translateService.instant(value));
    } else {
      this.openDefaultMenu();
    }
  }

  public setPlaceholder(): string {
    if (
      !this.auth?.auth_status &&
      !this.hasHistoricalClaims &&
      !this.mleEligible
    ) {
      return 'home_search_specialties_and_providers';
    }
    if (
      !!this.hasHistoricalClaims &&
      !!this.auth?.auth_status &&
      !!this.mleEligible
    ) {
      return 'home_search_specialties_procedures_and_providers';
    }
    if (
      !!this.hasHistoricalClaims &&
      !this.auth?.auth_status &&
      !!this.isPublicCost
    ) {
      return 'home_search_specialties_procedures_and_providers';
    }
    return 'home_search_specialties_and_providers';
  }

  public onKeyUp(event: KeyboardEvent, searchTerm: string): void {
    // Open up autosuggest on mobile device only when a user starts typing
    if (
      this.breakpoints.isMobile &&
      !this.mobileOverlayOpen &&
      event?.key !== KeyboardEvents.Tab
    ) {
      this.updateMobileOverlayStatus('open');
    }
    if (event?.key === KeyboardEvents.Enter && !this.skipOnEnter) {
      if (searchTerm === undefined && this.autocompleteIndex !== -1) {
        searchTerm = this.autocompleteList[this.autocompleteIndex].element.innerText;
      }
      if (!this.autocompleteFocused) {
        this.setSearch(searchTerm, event);
      } else {
        this.searchFocusedItem(searchTerm, event);
      }
    }
    this.skipOnEnter = false;
  }

  public onSearchButtonClick(event: PointerEvent, searchTerm: string): void {
    if (searchTerm) {
      this.setSearch(searchTerm, event)
    }
  }

  public onTermClear(key?, selected?): void {
    clearTimeout(this.focusCheck);
    if (!key || key === KeyboardEvents.Enter) {
      this.autocompleteResult.setValue(selected ? undefined : '');
      this.results = new AutocompleteResults();
      this.clearEvent.emit();
    }
  }

  public handleSelect(
    result: AutocompleteResult,
    type: SearchRouteType,
    event: KeyboardEvent | MatOptionSelectionChange | PointerEvent
  ): void {
    this.updateMobileOverlayStatus();
    if (!result.name || !/\S/.test(result.name)) {
      return;
    }

    if (event['isUserInput'] === false) {
      return;
    }

    if (type === 'telehealth') {
      this.autocompleteResult.setValue(
        this.translateService.instant(result.name)
      );
      this.openTelehealth(result);
      return;
    }

    const searchMethod = event['key'] === KeyboardEvents.Enter ?
      SearchMethods.FullText :
      SearchMethods.Autosuggest;
    this.store.dispatch(setSearchMethodAction({ searchMethod }));
    result.radius = null;
    result.type = type;
    this.autocompleteResult.setValue(result.name);
    this.termAutosuggest.onAutocompleteSelect(result, this.carepathEnabled);
    this.gtmTagEvent();
    this.recentAutoSuggestService.addRecentSearch(result);
    this.skipOnEnter = true;
  }

  public expandLimit($event: any, group: string): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.categories[group].showMore = false;
    this.categories[group].limit = this.categories[group].seeMoreMaxLimit;
  }

  public expandAllLimits($event: any): void {
    ['search_specialties', 'procedures', 'telehealth'].forEach(
      (group: string) => this.expandLimit($event, group)
    );
  }

  public specialtyFrom(result: any): string {
    if (result.primary_field_specialty) {
      return result.primary_field_specialty;
    } else {
      return result.specialty;
    }
  }

  public unFocusCheck(): void {
    this.focusCheck = setTimeout(() => {
      if (
        this.autocompleteResult.value === '' ||
        this.autocompleteResult.value?.length < 2
      ) {
        this.autocompleteResult.setValue(this.currentSearchTerm);
      }
    }, 200);
  }

  public toggleScrim(openState: boolean) {
    this.autosuggestOpen = openState;
    if (!openState) {
      this.selectChip('all_results');
      this.selectedGroup = '';
    }
  }

  public deleteRecentSearch(result: AutocompleteResult) {
    clearTimeout(this.focusCheck);
    this.input.focus();
    this.recentAutoSuggestService.deleteRecentSearch(result);
    this.openDefaultMenu();
  }

  public selectChip(group: string): void {
    this.selectedChip = group;
    this.input?.focus();
  }

  public closeAutoComplete(e: KeyboardEvent) {
    if (e?.key.toLowerCase() === 'tab' && this.autosuggestInput ) {
      this.toggleScrim(false);
      this.autosuggestInput.closePanel();
    }
  }

  private subToGlobalHeaderServiceTerm(): void {
    this.subscriptions.add(
      this.globalHeaderService.currentTerm.subscribe((term) => {
        if (this.autocompleteResult.value !== this.searchTriggerTerm) {
          this.autocompleteResult.setValue(this.searchTriggerTerm);
        }
      })
    );
  }

  private onEnter(searchTerm: string): void {
    this.googleTagManagerService.pushToDataLayer({
      event: 'gtm.entered_search',
      entered_search_text: searchTerm.trim(),
    });
    if (this.breakpoints.isMobile) {
      this.onTermClear(null, true);
    } else {
      this.resultsSubscription.unsubscribe();
      this.toggleScrim(false);
      this.autosuggestInput.closePanel();
    }
  }

  private openTelehealth(vendor: TelehealthVendor): void {
    window.open(vendor.link);
  }

  private reset(): void {
    Object.keys(this.categories).forEach((category: string) => {
      this.categories[category].limit = this.categories[category].defaultLimit;
    });
  }

  private subscribeToSearchTrigger(): void {
    this.subscriptions.add(
      this.searchTrigger.selectedTerm.subscribe((term: string) => {
        if (this.preserveTerm && term.length) {
          this.autocompleteResult.setValue(term);
          this.searchTriggerTerm = term;
          this.currentSearchTerm = this.autocompleteResult.value;
        }
        this.ref.detectChanges();
      })
    );
  }

  private subscribeToAutoSuggestTrigger(): Subscription {
    return this.searchTrigger.autosuggest
      .pipe(
        filter((autosuggestData) => !!autosuggestData),
        switchMap((autosuggestData: SearchTypeTrigger) => {
          this.autocompleteResult.setValue(autosuggestData.selectedTerm);
          this.initialTerm = autosuggestData.selectedTerm;
          this.selectedGroup = autosuggestData.page;
          this.triggerOrigin = autosuggestData.origin;
          this.termAutosuggest.selectGroup(autosuggestData.page);
          return this.termAutosuggest.getAutocompleteResults(
            autosuggestData.selectedTerm
          );
        })
      )
      .subscribe((results: AutocompleteResults) => this.setResults(results));
  }

  private subscribeToTermChanges(): Subscription {
    return this.autocompleteResult.valueChanges.subscribe(() => this.reset());
  }

  private resetSearchTrigger(): void {
    this.searchTrigger.autosuggest.next(null);
  }

  private gtmTagEvent(): void {
    const searchedValue =
      this.autocompleteResult.value === this.initialTerm
        ? ''
        : this.autocompleteResult.value;
    if (!!this.triggerOrigin) {
      this.googleTagManagerService.pushToDataLayer({
        event: `gtm.serp.${this.triggerOrigin}`,
        searched_autosuggest_value: searchedValue,
      });
    }
  }

  private initAutocompleteField(): void {
    this.autocompleteResult = new UntypedFormControl('');
    this.subscribeToDebouncedInputChanges();
  }

  private subscribeToDebouncedInputChanges(): void {
    this.subscriptions.add(
      this.autocompleteResult.valueChanges
        .pipe(
          filter((val) => val !== undefined),
          tap((val) => {
            if (val.length > 1) {
              this.isLoading = true;
            }
          }),
          debounceTime(225)
        )
        .subscribe((value: string) => this.onInputChange(value))
    );
  }

  private updateMobileOverlayStatus(value?: string): void {
    this.mobileOverlayOpen = !!value;
    this.updateBodyScroll();
  }

  private updateBodyScroll(): void {
    if (this.breakpoints.isMobile) {
      if (this.mobileOverlayOpen) {
        this.ccss.applyClassToBody('disable-background-layer');
        this.ccss.applyClassToHtml('disable-background-layer');
      } else {
        this.ccss.removeBodyClass('disable-background-layer');
        this.ccss.removeHtmlClass('disable-background-layer');
      }
    }
  }

  private getTelehealthVendors(): void {
    this.subscriptions.add(
      this.telehealthService.vendors
        .pipe(filter((data) => !!(data && data.length)))
        .subscribe((telehealthVendors: TelehealthVendor[]) => {
          this.telehealthVendors = telehealthVendors;
        })
    );
  }

  private openDefaultMenu(): void {
    this.resultsSubscription.unsubscribe();
    this.showNoResults = false;
    this.isLoading = true;
    this.results = new AutocompleteResults({
      showDefaultMenu: true,
      initialPrompt: [{ name: 'global_autosuggest_initial_search' }],
      recentSearches: this.recentAutoSuggestService.getRecentSearch(),
    });
    this.setLastGroup();
    if (this.autocompleteResult.value?.length > 1) this.updateMobileOverlayStatus('open');
    this.isLoading = false;
  }

  private setLastGroup(): void {
    this.groupOrder.forEach((group) => {
      if (this.results[group].length) {
        this.lastGroup = group;
      }
    });
  }

  private getAutocompleteResults(termEntered: string): void {
    this.isLoading = true;
    this.results = new AutocompleteResults();
    this.resultsSubscription.unsubscribe();
    this.resultsSubscription = this.termAutosuggest
      .getAutocompleteResults(termEntered)
      .subscribe((results) => this.setResults(results));
  }

  private subscribeToMleStatus(): void {
    this.subscriptions.add(
      this.mleService.status.subscribe((mleStatus: MleStatus) => {
        this.mleEligible = mleStatus && mleStatus.isEligible;
      })
    );
  }

  private subscribeToHasHistoricalClaimsConfig(): Subscription {
    return this.settingsService
      .getSetting('has_historical_claims', HasHistoricalClaimsConfig)
      .subscribe((setting: HasHistoricalClaimsConfig) => {
        return (this.hasHistoricalClaims = setting?.enabled);
      });
  }

  private getAuth(): void {
    this.subscriptions.add(
      this.store
        .pipe(
          select(AuthStoreSelectors.getAuthStatus),
          first((data) => data.resolved)
        )
        .subscribe((data) => (this.auth = data))
    );
  }

  private getDefaultMemberConfig(): void {
    this.subscriptions.add(
      this.membersService.defaultMember
        .pipe(distinctUntilChanged())
        .subscribe((member: DefaultMember) => {
          this.isPublicCost = !!member && member.inAllowedLocation;
        })
    );
  }

  private getCarepathEnabled(): void {
    this.subscriptions.add(
      this.carepathConfigService
        .isEnabled()
        .subscribe((enabled) => (this.carepathEnabled = enabled))
    );
  }

  private setResults(results: AutocompleteResults): void {
    !this.hasHistoricalClaims ? this.removeProcedures(results) : results;
    this.results = new AutocompleteResults();
    results = results ? results : new AutocompleteResults();
    this.mapResults(results);
    this.results = new AutocompleteResults(this.results);
    this.setNoResults();
    this.setLastGroup();
    this.isLoading = false;
  }

  private mapResults(results: AutocompleteResults): void {
    Object.keys(this.categories).forEach((category: string) => {
      if (this.skipCategory(category)) {
        return;
      }
      this.mapCategories(category, results);
      this.setShowSeeMore(category);
      this.addAvatar(category, results);
    });
  }

  private mapCategories(category: string, results: AutocompleteResults): void {
    const telehealth = category === 'telehealth';
    this.results[category] = telehealth
      ? this.getFilteredVendors(results[category])
      : results[category];
  }

  private skipCategory(category: string): boolean {
    const categories = ['recentSearches', 'initialPrompt', 'noResultsPrompt'];
    return categories.includes(category);
  }

  private setNoResults(): void {
    this.showNoResults =
      !this.results?.hasResults && this.autocompleteResult?.value?.length >= 2;
    if (this.showNoResults) {
      this.results = new AutocompleteResults({
        noResultsPrompt: [{}],
      });
    }
  }

  private removeProcedures(results: AutocompleteResults) {
    results.procedures = [];
    return results;
  }

  private getFilteredVendors(vendorCodes: string[]): TelehealthVendor[] {
    return this.telehealthService.getFilteredVendors(
      this.telehealthVendors,
      vendorCodes
    );
  }

  private setShowSeeMore(group: string): void {
    this.categories[group].showMore =
      this.results[group]?.length > this.categories[group].limit;
  }

  private addAvatar(category: string, results: AutocompleteResults): void {
    if (category === 'providers') {
      this.results[category] = results[category]?.map((provider) => {
        if (this.useOldAvatars) {
          provider.avatarPlaceholder = (provider.provider_type === 'P') ?
            'dls-illustration-stethoscope' :
            'dls-illustration-facility';
        } else {
          provider.avatarPlaceholder = getPlaceholderURL(provider);
        }
        return provider;
      });
    }
  }

  private getAvatarSetting(): void {
    this.subscriptions.add(
      this.settingsService
        .getSetting('use_old_avatars')
        .subscribe((avatar: boolean) => {
          this.useOldAvatars = avatar;
        })
    );
  }

  private setSearch(searchTerm: string, event: KeyboardEvent | MatOptionSelectionChange | PointerEvent): void {
    searchTerm = searchTerm.replace(/(<([^>]+)>)/gi, '');
    this.onEnter(searchTerm);
    this.handleSelect({ name: searchTerm }, 'name', event);
    this.pendoEvent.next(true);
  }

  private setUpAutocomplete(): void {
    this.autocompleteList = [];
    this.autocompleteFocused = true;

    this.zelisChips.forEach((item) => {
      this.autocompleteList.push({type: 'chip', element: item.nativeElement.children[0]})
    })

    this.listItems.forEach((item) => {
      const group = item.nativeElement.dataset.group;
      if (group !== 'initialPrompt') {
        this.autocompleteList.push({type: 'listItem', element: item.nativeElement, group});
      }
    });

    this.showMoreLinks.forEach((showMoreElement) => {
      const group = showMoreElement.nativeElement.dataset.group;
      const groupLimit = this.categories[group].limit;
      if (groupLimit !== this.results[group].length) {
        const groupKeys = this.autocompleteList.map(el => el?.group);
        const lastIndex = groupKeys.lastIndexOf(group) + 1;
        this.autocompleteList.splice(lastIndex, 0, {type: 'showMore', element: showMoreElement.nativeElement});
      }
    });
  }

  private searchFocusedItem(searchTerm: string, event: KeyboardEvent): void {
    const selectedItem = this.autocompleteList[this.autocompleteIndex];

    if (selectedItem.type === 'chip') {
      const chips = this.groupOrder.filter(group => this.results[group].length > 0);
      chips.unshift('all_results');
      this.selectChip(chips[this.autocompleteIndex]);
      this.setUpAutocomplete();
    }

    if (selectedItem.type === 'listItem') {
      const result = JSON.parse(selectedItem.element.dataset.result);
      this.autocompleteIndex = -1;
      this.autocompleteFocused = false;
      this.onEnter(searchTerm);
      this.handleSelect(result, result.type, event);
    }

    if (selectedItem.type === 'showMore') {
      const group = selectedItem.element.dataset.group;
      this.expandLimit(event, group);
      this.setUpAutocomplete();
    }
  }

  private focusNextItem(currentIndex: number, previousIndex: number): void {
    previousIndex = previousIndex === -1 ? 0 : previousIndex;
    this.autocompleteList[previousIndex].element.classList.remove('mat-mdc-chip-highlighted', 'mat-active');
    this.autocompleteList[currentIndex].element.classList.add('mat-mdc-chip-highlighted', 'mat-active');
    this.autocompleteList[currentIndex].element.scrollIntoView(false);
  }

  private handleAutocompleteKeydown(): void {
    this.globalAutocomplete._keyManager.onKeydown = (event: KeyboardEvent) => {
      this.setUpAutocomplete();
      switch (event.key) {
        case 'ArrowDown':
          this.autocompleteIndex = this.autocompleteIndex === this.autocompleteList.length - 1 ? 0 : this.autocompleteIndex + 1;
          const previousIndex = this.autocompleteIndex === 0 ? this.autocompleteList.length - 1 : this.autocompleteIndex - 1;
          this.focusNextItem(this.autocompleteIndex, previousIndex);
        break;

        case 'ArrowUp':
          const nextIndex = this.autocompleteIndex <= 0 ? this.autocompleteList.length - 1 : this.autocompleteIndex - 1;
          this.focusNextItem(nextIndex, this.autocompleteIndex);
          this.autocompleteIndex = nextIndex;
        break;
      }
    };
  }
}
